<template>
  <div>
    <div class="searchBox">
      <div class="searchLeft">
        <div>
          <el-input
              size="medium"
              clearable
              v-model="searchForm.name"
              placeholder="请输入话题名称"
              @keyup.enter.native="getTableList"
          ></el-input>
        </div>
        <el-button size="medium" type="primary" icon="el-icon-search" @click="getTableList">搜索</el-button>
      </div>
      <div class="searchRight">
        <el-button
          size="medium"
          type="warning"
          icon="el-icon-plus"
          @click="addFunc"
          >新增</el-button
        >
      </div>
    </div>

    <el-table border :data="tableData" height="76vh" style="width: 99%">
      <el-table-column prop="id" label="id"></el-table-column>
      <el-table-column prop="name" label="名称"></el-table-column>
      <el-table-column prop="img" label="图标">
        <template slot-scope="scope">
          <img :width="80" :src="scope.row.img" />
        </template>
      </el-table-column>
      <el-table-column prop="articleCount" label="文章数量"></el-table-column>
      <el-table-column prop="viewNum" label="浏览数"></el-table-column>
      <el-table-column prop="category" label="专题类型">
        <template slot-scope="scope">
          {{typeOptions[scope.row.category]}}
        </template>
      </el-table-column>
      <el-table-column prop="feedStatusTxt" label="首页推荐">
        <template slot-scope="scope">
          {{scope.row.feedStatus==1?'是':'否'}}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="更新时间"></el-table-column>
      <el-table-column label="操作" width="240">
        <template slot-scope="scope">
          <el-button size="small" type="primary" @click="toEdit(scope.row)"
            >编辑</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="cancelRecommend(scope.row)"
            v-if="!scope.row.feedStatus"
            >首页推荐</el-button
          >
          <el-button
            size="small"
            type="warning" 
            @click="cancelRecommend(scope.row)"
            v-else
            >取消推荐</el-button
          >
          <el-button
            size="small"
            type="danger" 
            @click="delFunc(scope.row)"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <pagination :totalNum="totalNum" :currentPage.sync="searchForm.pageNo" :pageSize.sync="searchForm.pageSize" @paginationChange="getTableList"></pagination>
    <el-dialog :title="`${ruleForm.id?'编辑':'添加'}话题`" :visible.sync="addVisible" width="600px">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="专题名称" prop="name" :rules="rules.blurRule">
          <el-input
            placeholder="请输入内容"
            v-model="ruleForm.name"
          ></el-input>
        </el-form-item>
        <el-form-item label="专题描述">
            <el-input
              type="textarea"
              :rows="5"
              placeholder="请输入内容"
              v-model="ruleForm.description">
            </el-input>
        </el-form-item>
        <el-form-item label="专题图标" prop="img" :rules="rules.typeIcon">
          <uploadImg @change="uploadImgChange" :imageFile="[{url:ruleForm.img}]"></uploadImg>
        </el-form-item>
        <el-form-item label="专题类型" prop="category" :rules="rules.changeRule">
          <el-select
            size="medium"
            clearable
            v-model="ruleForm.category"
            placeholder="类型"
          >
            <el-option
              v-for="(item,key) in typeOptions"
              :key="key"
              :label="item"
              :value="Number(key)">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      
      
      <span slot="footer" class="dialog-footer">
        <el-button @click="addVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitFunc">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import uploadImg from '@/components/uploadImg'
import pagination from '@/components/pagination'
export default {
  data() {
    return {
      totalNum: 0,
      addVisible: false,
      ruleForm: {
        id:"",
        name: "",
        description:"",
        img:"",
        category:""
      },
      searchForm: {
        name:'',
        pageNo: 1,
        pageSize: 10
      },
      typeOptions: {
        '1':'专题类型 01',
        '2':'专题类型 02',
        '3':'专题类型 03',
        '4':'推荐专题',
      },
      tableData: [],
      dialogVisible: false,
      rules: {
        blurRule: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        changeRule: [
          { required: true, message: "请选择", trigger: "change" },
        ],
        typeIcon: [{ required: true, message: "请上传图片", trigger: "change" }],
      },
    };
  },
  components:{uploadImg,pagination},
  created() {
    this.getTableList();
  },
  methods: {
    addFunc(){
      this.ruleForm = {};
      this.addVisible = true;
    },
    uploadImgChange(val){
      if(val&&val.length>0) {
        this.ruleForm.img=val[0].url
      }
    },
    toEdit(data) {
      //编辑
      this.ruleForm = {...data};;
      this.addVisible = true;
    },
    async submitFunc() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let res = await this.$http.topicCreate({...this.ruleForm});
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
            this.addVisible = false;
            this.$refs.ruleForm.resetFields();
          }
        }
      });
    },
    async getTableList(val) {
      if(val) {
        this.searchForm.pageNo = 1
      }
      let res = await this.$http.topicList({...this.searchForm});
      if (res) {
        this.tableData = res.result.data;
        this.totalNum = res.result.totalCount;
      }
    },

    cancelRecommend(row) {
      this.$confirm(`确定要${row.feedStatus?'取消':''}首页推荐么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.topicCreate({ ...row,feedStatus:row.feedStatus==1?0:1})
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
    delFunc(row) {
      this.$confirm(`确定要删除么？`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.topicDelete([row.id])
          if (res) {
            this.$message.success("操作成功！");
            this.getTableList();
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped>

</style>
